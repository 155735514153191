export { default as oil1 } from './oil1.jpg'
export { default as oil2 } from './oil2.jpg'
export { default as oil3 } from './oil3.jpg'
export { default as oil4 } from './oil4.jpg'
export { default as oil5 } from './oil5.jpg'
// export { default as oil6 } from './oil6.jpg'
// export { default as oil7 } from './oil7.jpg'
// export { default as oil8 } from './oil8.jpg'
export { default as oil9 } from './oil9.jpg'
export { default as oil10 } from './oil10.jpg'
export { default as oil11 } from './oil11.jpg'
export { default as oil12 } from './oil12.jpg'
export { default as oil13 } from './oil13.jpg'
export { default as oil14 } from './oil14.jpg'
export { default as oil15 } from './oil15.jpg'
export { default as oil16 } from './oil16.jpg'
export { default as oil17 } from './oil17.jpg'
export { default as oil18 } from './oil18.jpg'
export { default as oil19 } from './oil19.jpg'
export { default as oil20 } from './oil20.jpg'
export { default as oil21 } from './oil21.jpg'
export { default as oil22 } from './oil22.jpg'
export { default as oil23 } from './oil23.jpg'
export { default as oil24 } from './oil24.jpg'
export { default as oil25 } from './oil25.jpg'
export { default as oil26 } from './oil26.jpg'
export { default as oil27 } from './oil27.jpg'
export { default as oil28 } from './oil28.jpg'
export { default as oil29 } from './oil29.jpg'
export { default as oil30 } from './oil30.jpg'
export { default as oil31 } from './oil31.jpg'
export { default as oil32 } from './oil32.jpg'
export { default as oil33 } from './oil33.jpg'
export { default as oil34 } from './oil34.jpg'
export { default as oil35 } from './oil35.jpg'
export { default as oil36 } from './oil36.jpg'
export { default as oil37 } from './oil37.jpg'
export { default as oil38 } from './oil38.jpg'
export { default as oil39 } from './oil39.jpg'
export { default as oil40 } from './oil40.jpg'
export { default as oil41 } from './oil41.jpg'
export { default as oil42 } from './oil42.jpg'
export { default as oil43 } from './oil43.jpg'
export { default as oil44 } from './oil44.jpg'
export { default as oil45 } from './oil45.jpg'
export { default as oil46 } from './oil46.jpg'
export { default as oil47 } from './oil47.jpg'
export { default as oil48 } from './oil48.jpg'
export { default as oil49 } from './oil49.jpg'
export { default as oil50 } from './oil50.jpg'
export { default as oil51 } from './oil51.jpg'
export { default as oil52 } from './oil52.jpg'
export { default as oil53 } from './oil53.jpg'
export { default as oil54 } from './oil54.jpg'
export { default as oil55 } from './oil55.jpg'
export { default as oil56 } from './oil56.jpg'
