export { default as aipepople } from './AIpeople.jpg'
export { default as graduallyImprove } from './graduallyImprove.jpg'
export { default as mozg } from './mosg_shirok.jpeg'
export { default as mozg2 } from './new_tiny_brain.jpg'
export { default as reinforcement_learning } from './reinforcement_learning.jpg'
export { default as dextreme } from './dextreme.jpg'
export { default as tesseract } from './tesseract.jpg'
export { default as envpool } from './envpool.jpg'
export { default as door_key } from './door-key-curriculum.gif'
export { default as transferring_dexterous } from './transferring_dexterous.jpg'
export { default as accelerated_policy } from './accelerated_policy.jpg'
export { default as accelerated_policy2 } from './accelerated_policy2.jpg'
export { default as in_hand } from './in_hand.jpg'
export { default as non_smooth } from './non_smooth.jpg'
