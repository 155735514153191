import React from 'react'
import './Motherboard.scss'

export function Motherboard() {
    return (
        <div className="motherboard-wrapper">
            <div className="motherboard">
                <div className="motherboard-doulbler"></div>
            </div>
        </div>
    )
}
